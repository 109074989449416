import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';

export default function MyComponent( props ) {

	const { horizontal, reverse } = props

	const INTERSECTION_THRESHOLD = 0.1
	const [ appeared, setAppeared ] = useState( false )
	const [ intersectionRef, inView ] = useInView( {
		threshold: INTERSECTION_THRESHOLD
	} )

	const TRANSITION_OPTIONS = {
		'in': appeared,
		'timeout': 750,
		'classNames': 'component-animation'
	}

	useEffect(() => {
		inView && setAppeared( true )
	}, [ inView ] )

	return (
		<div
			className={`component-animation ${ horizontal ? 'component-animation--horizontal' : '' } ${ reverse ? 'reverse' : '' } ${ appeared ? '': 'hidden'}`}
			ref={ intersectionRef }>
			<CSSTransition { ...TRANSITION_OPTIONS }>
				{ props.children }
			</CSSTransition>
		</div>
	)
}
